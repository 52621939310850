import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ListItem, NumberedList, Link } from '@entur/typography';
import '../../sales/guides.scss';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "client-guide"
    }}>{`Client guide`}</h1>
    <p>{`This is a guide for clients on how to sell shared mobility through their own channels with the shared-mobility API.`}</p>
    <NumberedList className="client-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#before-you-start-your-implementation" mdxType="Link">
            Before you start your implementation
        </Link>
        <NumberedList className="client-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#agreement-with-the-transport-operator(s)" mdxType="Link">Agreement with the transport operator(s)</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#using-the-mobility-api-from-entur-to-show-available-products-in-a-map" mdxType="Link">Using the Mobility API from Entur to show available products in a map</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#sales-flow" mdxType="Link">
            Sales flow
        </Link>
        <NumberedList mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#authentication" mdxType="Link">Authentication</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#headers" mdxType="Link">Headers</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#get-vehicleid-gbfs-given-qr-code" mdxType="Link">Get vehicleId (GBFS) given QR-code</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#create-a-booking" mdxType="Link">Create a booking</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#change-the-state-of-the-booking" mdxType="Link">Change the state of the booking</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#get-bookings-given-customer-id" mdxType="Link">Get bookings given customer ID</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#get-a-booking-given-booking-id" mdxType="Link">Get a booking given booking ID</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#error-handling" mdxType="Link">Error handling</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    </NumberedList>
    <h3 {...{
      "id": "before-you-start-your-implementation"
    }}>{`Before you start your implementation`}</h3>
    <p>{`Good to know: assetId (TOMP id for a product), vehicleId and bike_id (both GBFS id for a product) is the same id.`}</p>
    <h4 {...{
      "id": "agreement-with-the-transport-operators-you-want-to-sell-products-for"
    }}>{`Agreement with the transport operator(s) you want to sell products for`}</h4>
    <ul>
      <li parentName="ul">{`The client must have permission to sell products on behalf of a transport operator`}</li>
      <li parentName="ul">{`The client must send the necessary documentation for settlement to Entur`}</li>
    </ul>
    <h4 {...{
      "id": "using-the-mobility-api-from-entur-to-show-available-products-in-a-map"
    }}>{`Using the Mobility API from Entur to show available products in a map`}</h4>
    <p>{`To find and show available shared mobility products, use the `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-mobility-docs-mobility-v2"
      }}>{`Mobility API`}</a>{`.`}</p>
    <h3 {...{
      "id": "sales-flow"
    }}>{`Sales Flow`}</h3>
    <p>{`After setting up integration with Entur's Mobility API, you can now use the Shared Mobility API to book products.`}</p>
    <p>{`You can find the swagger doc for the shared-mobility application here: `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref"
      }}>{`https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref`}</a>{`.`}</p>
    <p>{`Note that the endpoints under the "TOMP endpoints" section should only be used by the transport operators to inform us about changes in the journey from their side.
These must not be used by the clients.`}</p>
    <p>{`Booking a product can be done in two ways:`}</p>
    <ul>
      <li parentName="ul">{`Selecting a product from the map: By choosing a product from the map, you can use the GBFS data from the Mobility API to display information about the product
(typically price, battery status, location and the distance the unit can travel), check if you have the right to sell the product, and use the assetId to create a booking.`}</li>
      <li parentName="ul">{`Scanning a QR code: If you want to add functionality for scanning a QR code and then displaying product information, you first need to make a call to
"GET /shared-mobility/v1/qr/qr-code" to retrieve the assetId/vehicleId/bike_id from the scanned QR-code. After that, the sales flow continues as usual.`}</li>
    </ul>
    <h4 {...{
      "id": "authentication"
    }}>{`Authentication`}</h4>
    <p>{`The client should authenticate with the shared-mobility API by providing an OAuth2 bearer token in the request header. The token should be issued by Entur and have the necessary scopes to access the API.
In addition, a header for a specific distribution channel must be provided in the request when booking a trip with shared mobility.
Only distribution channels that have been registered with Entur and have been linked with your client will be accepted.`}</p>
    <p>{`In order to issue a valid token you must have a `}<a parentName="p" {...{
        "href": "/pages-intro-setup-and-access#access-partner-services"
      }}>{`client_id and client_secret`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl--request POST \\
--url 'https://<type_of_token>.<environment>.entur.org/oauth/token' \\
--header 'content-type: application/json' \\
--data '{"grant_type":"client_credentials","client_id": "<clientid>","client_secret": "<clientsecret>","audience": "https://api.<environment>.entur.io"}
`}</code></pre>
    <h4 {...{
      "id": "headers"
    }}>{`Headers`}</h4>
    <p>{`These headers should be used inn all calls towards the shared-mobility API:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Header key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developer.entur.org/distribution-channels-intro"
            }}>{`Distribution Channel`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entur-Distribution-Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ENT:DistributionChannel:app`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://developer.entur.org/pages-intro-setup-and-access"
            }}>{`Authorization`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authorization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Bearer xxxxxxx.yyyyyyyy.zzzzzzzzz`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`The preferred `}<a parentName="td" {...{
              "href": "https://en.wikipedia.org/wiki/ISO_639-3"
            }}>{`language code`}</a>{` for error messages etc.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Language-Code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`NOB`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "check-if-you-can-sell-the-product-chosen-from-the-map-or-scanned-by-a-qr-code"
    }}>{`Check if you can sell the product chosen from the map or scanned by a QR-code`}</h4>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility#/Operator/getAvailability"
      }}>{`GET /shared-mobility/v1/operators/availability`}</a></p>
    <p>{`Returns a list of transport operators you can sell products on behalf of. If a vehicle's operator id (vehicle.system.operator.id in the Mobility API)
is included in this list, you can use the Shared Mobility service to book it.`}</p>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
  "operatorIds": ["ENT:Operator:ENT"]
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-vehicleid-gbfs-given-qr-code"
    }}>{`Get vehicleId (GBFS) given QR-code`}</h4>
    <p>{`GET /shared-mobility/v1/qr/qr-code`}</p>
    <p>{`Retrive the assetId (TOMP) / vehicleId (GBFS) /bike_id (GBFS) given the scanned QR-code`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "qrCode": "https://some-operator.com/vehicleCode=1234ABCD"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
  "operatorId": "ENT:Operator:ENT",
  "vehicleId": "ENT:Vehicle:00000000-abcd-abcd-abcd-00000000"
  "stationId": null
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "create-a-booking"
    }}>{`Create a booking`}</h4>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility#/Booking/oneStop"
      }}>{`POST /shared-mobility/v1/bookings/one-stop`}</a></p>
    <p>{`Create a one stop booking. If you want to make sure that the trip starts right away you can set autoStart to true.`}</p>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "customerId": 123456,
  "recurringPaymentId": 123456,
  "coordinates": {
    "longitude": 0,
    "latitude": 0,
    "altitude": 0
  },
  "assetId": "ENT:Vehicle:00000000-abcd-abcd-abcd-00000000",
  "autoStart": true
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
  "bookingId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "state": "CONFIRMED",
  "legState": "IN_USE",
  "orderId": "ABCD1234",
  "pricingPlan": {
    "currency": "NOK",
    "price": 10,
    "perMinPricing": [
      {
        "start": 0,
        "end": 0,
        "interval": 1,
        "rate": 1.5
      }
    ]
  },
  "departureTime": "2024-08-12T10:31:17.878Z",
  "arrivalTime": "2024-08-12T10:31:17.878Z",
  "operator": {
    "id": "ENT:OPERATOR:ENT",
    "name": "Entur AS"
  },
  "stateOfCharge": 50,
  "currentRangeKm": 20,
  "pricing": {
    "currentAmount": 100.00,
    "finalAmount": 0
  }
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "change-the-state-of-the-booking"
    }}>{`Change the state of the booking`}</h4>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility#/Booking/oneStopEvent"
      }}>{`POST /shared-mobility/v1/bookings/{bookingId}/one-stop/event`}</a></p>
    <p>{`Use this endpoint to change the state of a booking:`}</p>
    <ul>
      <li parentName="ul">{`START: Start the trip`}</li>
      <li parentName="ul">{`PAUSE: Pause the trip`}</li>
      <li parentName="ul">{`RESUME: Resume the trip after pause`}</li>
      <li parentName="ul">{`START_FINISHING: State usually used when the transport operator want the user to take a picture of the mobility etc. The traveler stops paying more for the journey after this step in the journey.`}</li>
      <li parentName="ul">{`FINISH: Finish the trip (if a picture of the product is mandatory when finishing the trip, you have to set fileName, fileType and fileData)`}</li>
      <li parentName="ul">{`CANCEL: Cancel the trip`}</li>
    </ul>
    <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>{`{
  "event": "START",
  "fileName": "string",
  "fileType": "string",
  "fileData": "string"
}`}</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
  "bookingId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "state": "CONFIRMED",
  "legState": "IN_USE",
  "orderId": "ABCD1234",
  "pricingPlan": {
    "currency": "NOK",
    "price": 10,
    "perMinPricing": [
      {
        "start": 0,
        "end": 0,
        "interval": 1,
        "rate": 1.5
      }
    ]
  },
  "departureTime": "2024-08-12T10:31:17.878Z",
  "arrivalTime": "2024-08-12T10:31:17.878Z",
  "operator": {
    "id": "ENT:OPERATOR:ENT",
    "name": "Entur AS"
  },
  "stateOfCharge": 50,
  "currentRangeKm": 20,
  "pricing": {
    "currentAmount": 0,
    "finalAmount": 100.00
  }
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-bookings-given-customer-id"
    }}>{`Get bookings given customer ID`}</h4>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility#/Booking/getBookings"
      }}>{`GET /shared-mobility/v1/bookings`}</a></p>
    <p>{`Get a list of all bookings given customerId. If you set the parameter "active" to true, the endpoint will return all bookings who is not in FINISHED or CANCELLED state.
This is usually used when retrieving an active booking if the user closes the app in the middle of a trip.`}</p>
    <ExpandablePanel title="Example Request" mdxType="ExpandablePanel">
    <pre>/shared-mobility/v1/bookings?customerId=1234567&active=true</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`[{
  "bookingId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "state": "CONFIRMED",
  "legState": "IN_USE",
  "orderId": "ABCD1234",
  "pricingPlan": {
    "currency": "NOK",
    "price": 10,
    "perMinPricing": [
      {
        "start": 0,
        "end": 0,
        "interval": 1,
        "rate": 1.5
      }
    ]
  },
  "departureTime": "2024-08-12T10:31:17.878Z",
  "arrivalTime": "2024-08-12T10:31:17.878Z",
  "operator": {
    "id": "ENT:OPERATOR:ENT",
    "name": "Entur AS"
  },
  "stateOfCharge": 50,
  "currentRangeKm": 20,
  "pricing": {
    "currentAmount": 0,
    "finalAmount": 100.00
  }
},
{
  "bookingId": "2c963f66afa6-b3fc-5717-4562-3fa85f64",
  "state": "CONFIRMED",
  "legState": "IN_USE",
  "orderId": "1234ABCD",
  "pricingPlan": {
    "currency": "NOK",
    "price": 10,
    "perMinPricing": [
      {
        "start": 0,
        "end": 0,
        "interval": 1,
        "rate": 1.5
      }
    ]
  },
  "departureTime": "2024-08-12T10:31:17.878Z",
  "arrivalTime": "2024-08-12T10:31:17.878Z",
  "operator": {
    "id": "ENT:OPERATOR:ENT",
    "name": "Entur AS"
  },
  "stateOfCharge": 50,
  "currentRangeKm": 20,
  "pricing": {
    "currentAmount": 0,
    "finalAmount": 100.00
  }
}]`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "get-a-booking-given-booking-id"
    }}>{`Get a booking given booking ID`}</h4>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility#/Booking/getBooking"
      }}>{`GET /shared-mobility/v1/bookings/{bookingId}`}</a></p>
    <p>{`Get a specific booking given bookingId.`}</p>
    <ExpandablePanel title="Example Request" mdxType="ExpandablePanel">
    <pre>/shared-mobility/v1/bookings/3fa85f64-5717-4562-b3fc-2c963f66afa6</pre>
    </ExpandablePanel>
    <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>{`{
  "bookingId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "state": "CONFIRMED",
  "legState": "IN_USE",
  "orderId": "ABCD1234",
  "pricingPlan": {
    "currency": "NOK",
    "price": 10,
    "perMinPricing": [
      {
        "start": 0,
        "end": 0,
        "interval": 1,
        "rate": 1.5
      }
    ]
  },
  "departureTime": "2024-08-12T10:31:17.878Z",
  "arrivalTime": "2024-08-12T10:31:17.878Z",
  "operator": {
    "id": "ENT:OPERATOR:ENT",
    "name": "Entur AS"
  },
  "stateOfCharge": 50,
  "currentRangeKm": 20,
  "pricing": {
    "currentAmount": 0,
    "finalAmount": 100.00
  }
}`}</pre>
    </ExpandablePanel>
    <h4 {...{
      "id": "error-handling"
    }}>{`Error handling`}</h4>
    <p>{`All error objects look the same and follow this structure:`}</p>
    <ExpandablePanel title="Example exception" defaultOpen={true} mdxType="ExpandablePanel">
    <pre>{`{
  "timestamp": "2024-09-17T08:49:33.814422231+02:00",
  "status": 409,
  "error": "Conflict",
  "exception": "VehicleNotFoundException",
  "message": "Could not start trip. This vehicle is not available right now.",
  "path": "/bookings/one-stop"
}`}</pre>
    </ExpandablePanel>
    <p>{`The message is provided as human-readable text, translated into Norwegian Bokmål, Norwegian Nynorsk, or English.
The language is determined by the 'Accept-Language' header in the request.`}</p>
    <p>{`Here is a list of all error messages:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`When`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`What`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NBO`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NNO`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ENG`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Scan QR-code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Find info about operator and vehicle given QR-code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke finne kjøretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje finne køyretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not find asset.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking, start trip, finish trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Check if transport operator support chosen service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operatøren tilbyr ikke denne tjenesten.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operatøren tilbyr ikkje denne tenesta`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator does not provide this service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Get info about asset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Dette kjøretøyet er ikke tilgjengelig akkurat nå.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Dette køyretøyet er ikkje tilgjengeleg akkurat no.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. This vehicle is not available right now.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Get info about asset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prisplanen som tilbys er ikke støttet av dette systemet.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prisplanen som blir tilbydd er ikkje støtta av dette systemet.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The pricing plan offered is not supported by this system.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Get info about transport operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operatør av kjøretøy ukjent.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operatør av køyretøy ukjent.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Operator of vehicle unknown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Check access`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Din leverandør har ikke tilgang til dette kjøretøyet.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leverandøren din har ikkje tilgang til dette køyretøyet.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your provider does not have access to this vehicle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Check recurring payment agreement`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Vi fant ingen gyldig betalingsmetode. Prøv igjen med et annet kort.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Vi fann ingen gyldig betalingsmetode. Prøv igjen med eit anna kort.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. We could not find a valid payment method. Try again with a different card.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create one-stop booking with a transport operator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Forsøket ble avslått av {operator}. {406:message}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Forsøket vart avslått av {operator}. {406: Message}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. The attempt was rejected by {operator}. {406: Message}`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Prøv igjen eller velg et annet kjøretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Prøv igjen eller vel eit anna køyretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. Try again or choose another vehicle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add a third party product`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Prøv igjen eller velg et annet kjøretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Prøv igjen eller vel eit anna køyretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. Try again or choose another vehicle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Prøv igjen eller velg et annet kjøretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Prøv igjen eller vel eit anna køyretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. Try again or choose another vehicle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add transaction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Prøv igjen eller velg et annet kjøretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Prøv igjen eller vel eit anna køyretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. Try again or choose another vehicle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create terminal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Prøv igjen eller velg et annet kjøretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Prøv igjen eller vel eit anna køyretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. Try again or choose another vehicle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create booking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Reserve payment amount`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke starte tur. Vi klarte ikke å reservere beløpet.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje starte tur. Vi klarte ikkje å reservere beløpet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not start trip. We could not reserve the amount.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Start trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change state of trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke endre tilstanden på turen. Leverandøren avslo forsøket. Prøv igjen eller velg et annet kjøretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje endre tilstanden på turen. Leverandøren avslo forsøket. Prøv igjen eller vel eit anna køyretøy.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not change state of trip. The provider refused the attempt. Try again or choose another vehicle.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change state of trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke avslutte tur. Leverandøren avslo forsøket. Prøv igjen eller ta kontakt med {operator}`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje avslutte tur. Leverandøren avslo forsøket. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not end trip. The provider declined the attempt. Try again or contact {operator}.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unlock order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke avslutte tur. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje avslutte tur. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not end trip. Try again or contact {operator}.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Update order`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke avslutte tur. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje avslutte tur. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not end trip. Try again or contact {operator}.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Adjust payment amount`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke avslutte tur. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje avslutte tur. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not end trip. Try again or contact {operator}.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish trip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikke avslutte tur. Det oppstod problemer med betalingen. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kunne ikkje avslutte tur. Det oppstod problemer med betalinga. Prøv igjen eller ta kontakt med {operator}.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Could not end trip. There was a problem with the payment. Try again or contact {operator}.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      